import { Navigate, Route, Routes } from 'react-router-dom';
import { FC, Suspense } from 'react';
import Layout from 'components/Base/Layout';
import { appRoutes } from './RoutesData';
import { RoutesPaths } from './Routes.types';

const PublicRoutes: FC = () => {
  return (
    <Suspense fallback={<Layout />}>
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="/"
            element={<Navigate replace to={appRoutes[0].path} />}
          />
          {appRoutes.map(({ path, component }) => {
            const Component = component;
            return (
              <Route key={path} path={`${path}`} element={<Component />} />
            );
          })}
          <Route
            path="*"
            element={<Navigate to={RoutesPaths.PAGE_NOT_FOUND} />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default PublicRoutes;
