import { Paragraph, Title } from 'components/Base/Typography';
import { Col } from 'components/Base/Grid';
import { TErrorPageViewProps } from './ErrorPageView.types';
import { SErrorPageWrapper, SImage } from './ErrorPageView.styles';

const ErrorPageView = ({
  imgSrc,
  alt,
  title,
  description,
}: TErrorPageViewProps) => {
  return (
    <SErrorPageWrapper justify="center" className="py-10">
      <Col>
        <SImage src={imgSrc} alt={alt} aria-label="hidden" />
        <Title level={2} color="#00779f">
          {title}
        </Title>
        <Paragraph>{description}</Paragraph>
      </Col>
    </SErrorPageWrapper>
  );
};

export default ErrorPageView;
