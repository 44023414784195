import { Location } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { EWorkshopAgeGroup } from '../data/types/workshops.types';

export {
  dateConverter,
  dateFormatDB,
  dateFormatUI,
  dateTimeFormatUI,
  dateTimeFormatDB,
  dateTimeWithoutSecUI,
  dateFormatWithWeekdayAndTime,
  dateFormatWithYearWeekdayAndTime,
} from '@idaho-aeyc/ui-kit';

export const getMaskEmail = (email: string) => {
  const [name, domain] = email.split('@');
  const maskedName = name.substring(0, 3) + '*'.repeat(name.length - 3);
  return `${maskedName}@${domain}`;
};

export const generateSpaceClassNames = (array: number[]) => {
  let classNames = ``;
  array.forEach((n, i) => {
    classNames += `
                .mx-${i} {
                    margin: 0 ${n}px !important;
                }
                .my-${i} {
                    margin: ${n}px 0 !important;
                }
                .m-${i} {
                    margin: ${n}px !important;
                }
                .mt-${i} {
                    margin-top: ${n}px !important;
                }
                .mb-${i} {
                    margin-bottom: ${n}px !important;
                }
                .ml-${i} {
                    margin-left: ${n}px !important;
                }
                .mr-${i} {
                    margin-right: ${n}px !important;
                }
                .pt-${i} {
                    padding-top: ${n}px !important;
                }
                .pb-${i} {
                    padding-bottom: ${n}px !important;
                }
                .pl-${i} {
                    padding-left: ${n}px !important;
                }
                .pr-${i} {
                    padding-right: ${n}px !important;
                }
                .px-${i} {
                    padding: 0 ${n}px !important;
                }
                .py-${i} {
                    padding: ${n}px 0 !important;
                }
                .p-${i} {
                    padding: ${n}px !important;
                }
            `;
  });

  return classNames;
};

export const checkDateIsBefore = (current: dayjs.Dayjs) => {
  const dateNow = dayjs(); // Assuming `dateNow` represents the current date
  return current && current.isBefore(dateNow);
};

export const checkDateIsAfter = (current: dayjs.Dayjs) => {
  const dateNow = dayjs(); // Assuming `dateNow` represents the current date
  return current && current.isAfter(dateNow);
};

export const convertStateToUpperCase = (value: string) => {
  return value.toUpperCase().replace(/\s+/g, '_');
};

export const separateDateAndTime = (date: string) => {
  const dateObj = new Date(date);
  const formatDate = dateObj.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  const formatTime = dateObj.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  return {
    date: formatDate,
    time: formatTime,
  };
};

export const getSuggestedAgeGroupData = (
  birthdate: Dayjs | null,
): EWorkshopAgeGroup | null | string => {
  if (!birthdate) return null;
  const currentDate = dayjs();
  const currentYear = currentDate.year();
  const birthYear = birthdate.year();

  // Check the year difference
  let age = currentYear - birthYear;
  // Adjust for the September 1 breakpoint
  if (age >= 1 && birthdate.month() >= 8) {
    age -= 1;
  }

  switch (age) {
    case 0:
      return EWorkshopAgeGroup.ZERO_TO_ONE;
    case 1:
      return EWorkshopAgeGroup.ONE_TO_TWO;
    case 2:
      return EWorkshopAgeGroup.TWO_TO_THREE;
    case 3:
      return EWorkshopAgeGroup.THREE_TO_FOUR;
    case 4:
      return EWorkshopAgeGroup.FOUR_TO_FIVE;
    default:
      return EWorkshopAgeGroup.FOUR_TO_FIVE;
  }
};

export const calcYear = (val: number): number => {
  const currentDate = dayjs();
  const currentMonth = currentDate.month();
  const currentYear = new Date().getFullYear();
  if (currentMonth < 8) {
    return currentYear + val - 1;
  }
  return currentYear + val;
};

export const groupAdjacentAges = (ageGroups: string[]): string[] => {
  const ageEnum = {
    ZERO_TO_ONE: '0-1',
    ONE_TO_TWO: '1-2',
    TWO_TO_THREE: '2-3',
    TWO_TO_FIVE: '2-5',
    THREE_TO_FOUR: '3-4',
    THREE_TO_FIVE: '3-5',
    FOUR_TO_FIVE: '4-5',
  };

  if (ageGroups.length === 0) {
    return [];
  }

  const sortedAgeGroups = ageGroups
    .map(ageGroup =>
      ageEnum[ageGroup as keyof typeof ageEnum].split('-').map(Number),
    )
    .sort((a, b) => a[0] - b[0]);

  const groupedAgeGroups: number[][] = [sortedAgeGroups[0]];

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < sortedAgeGroups.length; i++) {
    const currentGroup = groupedAgeGroups[groupedAgeGroups.length - 1];
    const nextGroup = sortedAgeGroups[i];

    if (currentGroup[1] === nextGroup[0]) {
      currentGroup[1] = Math.max(currentGroup[1], nextGroup[1]);
    } else {
      groupedAgeGroups.push(nextGroup);
    }
  }

  return groupedAgeGroups.map(([start, end]) => `${start}-${end}`);
};

export const formatPhoneNumber = (value: string | null) => {
  if (!value) {
    return '';
  }
  // if phone number is not us number do not format it
  if (!value.startsWith('+1') || !(value.length === 12)) {
    return value;
  }
  if (value) {
    // Remove the +1 prefix and format as (xxx) xxx-xxxx
    const numericValue = value.replace('+1', '');
    const formattedPhoneNumber = `(${numericValue.substring(
      0,
      3,
    )}) ${numericValue.substring(3, 6)}-${numericValue.substring(6, 10)}`;

    return formattedPhoneNumber;
  }

  return null;
};

export const cutOffHoistIdFromSearchParams = (location: Location) => {
  const searchParams = new URLSearchParams(location.search);
  searchParams.delete('hostId');
  return searchParams.toString();
};
