import { Row, Col } from 'components/Base/Grid';
import styled from 'styled-components';

export const SFooter = styled(Row)`
  width: 100%;
  background-color: #3378a2;
  padding: 32px 3.3vmax;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  h3 {
    color: #fff;
    margin-bottom: 32px;
    font-size: calc((2.2 - 1) * calc(0.012 * min(100vh, 900px)) + 1rem);
  }
  .contact {
    margin: 1rem 0;
    font-size: calc((1.4 - 1) * 1.2vw + 1rem);
    color: #7dcef1;
    display: inline-block;
    background-repeat: unset;
    background-image: unset;
    background-size: unset;
    background-position: unset;
    text-underline-offset: 0.2em;
    text-decoration: underline;
  }
  .content {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 32px;
  }
`;

export const SMobileLogo = styled(Col)`
  margin-bottom: 62px;
`;

export const SButtonsWrapper = styled(Row)`
  width: 100%;
  .button-wrapper {
    flex-grow: 1;
    width: calc(100% / 5);
  }
  @media (max-width: 914px) {
    flex-direction: column;
    .button-wrapper {
      width: inherit;
      .button {
        height: 42px;
      }
    }
  }
`;

export const SLinkButton = styled.button`
  font-family: Poppins;
  border: none;
  display: flex;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0.02em;
  font-size: 16px;
  background: #d60d5f;
  padding: 1.2em 2.004em;
  border-radius: 300px;
  align-items: center;
  line-height: normal;
  justify-content: center;
  width: 100%;
  height: 78px;
  &:hover {
    opacity: 0.8;
    color: #fff;
  }
  @media (max-width: ${({ theme }) => theme.screenMd}px) {
    width: 100%;
    height: 59px;
  }
`;
