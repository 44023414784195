import { useRef, useState } from 'react';
import { useOnClickOutside } from 'hooks/useOnOutsideClickHandler';
import logo from 'assets/images/READYlogo.png';
import { Col, Row } from 'components/Base/Grid';
import { Image } from 'components/Base/Image';
import { RoutesPaths } from 'routes/Routes.types';
import Burger from '../Burger';
import MobileNavBar from '../MobileNavBar';
import { SMobileHeaderLayout, SMobileHeader } from './MobileHeader.styles';

export const Header = () => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));
  const onImageClick = () => {
    window.location.replace(RoutesPaths.HOME);
  };
  return (
    <SMobileHeaderLayout $open={open}>
      <SMobileHeader>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <Image
                className="pointer"
                src={logo}
                alt="header logo"
                height={48}
                width={58}
                onClick={onImageClick}
              />
            </Col>
            <Col>
              <Burger open={open} setOpen={setOpen} />
            </Col>
          </Row>
        </Col>
      </SMobileHeader>
      <MobileNavBar open={open} />
    </SMobileHeaderLayout>
  );
};

export default Header;
