import styled from 'styled-components';

export const StyledBurger = styled.button<{ $open: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 35px;
  height: 35px;

  div {
    width: 100%;
    height: 1px;
    background: #3378a2;
    border-radius: 10px;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    content: '';
    width: 100%;
    transition: transform 250ms cubic-bezier(0.2, 0.6, 0.3, 1),
      width 250ms cubic-bezier(0.2, 0.6, 0.3, 1);
    will-change: transform, width;

    &:first-child {
      transform: translateY(-5.5px);
      ${({ $open }) => $open && 'transform: translateX(3.5px) rotate(135deg);'};
    }

    &:nth-child(2) {
      transform: translateY(5.5px);
      ${({ $open }) =>
        $open && 'transform: translateX(3.5px) rotate(-135deg);'};
    }
  }
`;
