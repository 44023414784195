export enum EWorkshopsUrls {
  WORKSHOPS = 'workshop-app',
  WORKSHOP_EVENT = 'workshop-event',
  REGISTRATION = 'registration',
  WORKSHOP_CITIES = 'workshop-event/location/cities',
  PARENT_ORDERING = 'parent-ordering',
  SHIPPING_DETAILS = 'shipping-details',
  VALIDATE = 'validate',
  SUBSCRIBE = 'subscribe',
  IDAHO = 'idaho',
  CITIES = 'cities',
  FREE_SLOT = 'free-slot',
  FORM = 'form',
  MEETING_LINK = 'meeting-link',
  LIGHT_BOX = 'lightbox',
}

export const meetingLinkUrl = `${EWorkshopsUrls.WORKSHOPS}/${EWorkshopsUrls.WORKSHOP_EVENT}/${EWorkshopsUrls.MEETING_LINK}`;
export const getRegistrationModalDataUrl = `${EWorkshopsUrls.WORKSHOPS}/${EWorkshopsUrls.REGISTRATION}/${EWorkshopsUrls.FORM}`;
export const getRegistrationFreeSlotsUrl = `${EWorkshopsUrls.WORKSHOPS}/${EWorkshopsUrls.REGISTRATION}/${EWorkshopsUrls.FREE_SLOT}`;
