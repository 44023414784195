import React, { FC, ReactElement, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';

import appSlice from 'redux/slices/app.slice';
import {
  ERedirectActionTypeDB,
  ERedirectActionTypeUI,
} from 'data/types/redirect.types';
import { redirectsMapper } from 'data/mappers/redirectMapper';
import { redirectActionTypeConverter } from 'data/convertors/redirect.converters';

export const RedirectLayer: FC<{
  children: ReactElement;
}> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    const actionType = redirectActionTypeConverter.fromDb(
      searchParams.get('action') as ERedirectActionTypeDB,
    );
    const id = searchParams.get('id');
    const resourceId = searchParams.get('resourceId');

    if (searchParams.get('action')) {
      searchParams.delete('action');
      const notificationObj = redirectsMapper[actionType];
      let { url } = notificationObj;
      if (id) {
        url += `/${id}`;
      }
      if (resourceId) {
        url += `?${searchParams.toString()}`;
        if (actionType === ERedirectActionTypeUI.SHOW_HOST_EVENT) {
          url = url.replace('resourceId', 'hostId');
        }
      }
      if (!notificationObj.private) {
        navigate(url);
        return;
      }
      navigate(url);
      // this link will be set in private routes
      dispatch(appSlice.actions.setCurrentNotificationUrl(url));
    }
  }, []);

  return <>{children}</>;
};
