import React from 'react';
import { LoadScript } from '@react-google-maps/api';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ThemeWrapper from 'theme/ThemeWrapper';
import { RedirectLayer } from 'components/Shared/RedirectLayer';
import { sentryInitialization } from 'configs/sentry.config';
import ErrorBoundary from './ErrorBoundary';
import { store } from './redux/store';
import RoutesPage from './routes';

function App() {
  const googleMapApiKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY || '';
  if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    sentryInitialization();
  }

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Provider store={store}>
          <RedirectLayer>
            <ThemeWrapper>
              <LoadScript
                googleMapsApiKey={googleMapApiKey}
                libraries={['places']}
                language="en"
              >
                <RoutesPage />
              </LoadScript>
            </ThemeWrapper>
          </RedirectLayer>
        </Provider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
