import { navbarRoutes } from 'routes/RoutesData';
import { useLocation } from 'react-router-dom';
import { SMobileNavBar, SNavItem } from './MobileNavBar.styles';
import { TMobileNavBarProps } from './MobileNavBar.types';

const MobileNavBar = ({ open }: TMobileNavBarProps) => {
  const { pathname } = useLocation();
  return (
    <SMobileNavBar $open={open}>
      {navbarRoutes.map(({ path, title }) => (
        <SNavItem key={path} $active={pathname.replace('/', '') === path}>
          <a href={path}>
            <span>{title}</span>
          </a>
        </SNavItem>
      ))}
    </SMobileNavBar>
  );
};
export default MobileNavBar;
