import styled from 'styled-components';
import { Row } from 'components/Base/Grid';

export const SMobileHeaderLayout = styled.div<{ $open: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: absolute;
  ${({ $open }) => ($open ? 'overflow: hidden;' : '')}
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  overflow: hidden;
`;

export const SMobileHeader = styled(Row)`
  padding: 6vw;
  width: 100%;
  max-height: 79px;
  background-color: white;
  z-index: 99;
  position: fixed;
  top: 0px;
`;
