import styled from 'styled-components';

export const SDesktopNavBar = styled.nav`
  text-align: right;
  margin-left: auto;
  padding-left: 1vw;
  display: flex;
`;

export const SNavItem = styled.div<{ $active: boolean }>`
  font-family: Poppins;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0em;
  text-transform: none;
  line-height: 1.8em;
  font-size: 16px;
  &:not(:first-child) {
    margin-left: 1.3vw;
  }
  a {
    backface-visibility: hidden;
    padding: 0.1em 0;
    display: block;
    color: #3378a2;
    ${props =>
      props.$active &&
      `background-image: linear-gradient(currentColor,currentColor);
    background-repeat: repeat-x;
    background-size: 1px 1px;
    background-position: 0 100%;`}
  }
`;
