import { EI18nLanguageUI } from '@packages/utils';
import {
  TConvertor,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/converter.types';
import {
  EAllowedOperations,
  EI18nLanguageDB,
  TAddressTypesDB,
  TAddressTypesUI,
  TAllowedOperationsDB,
  TAllowedOperationsUI,
  TAllowedOperationsValueDB,
  TAllowedOperationsValueUI,
  TOption,
  TPageableDataDB,
  TPageableDataUI,
  TYesNoOptionType,
} from 'data/types/generic.types';

import {
  convertStateToUpperCase,
  dateConverter,
  dateFormatUI,
  formatPhoneNumber,
} from 'helpers/utils';

export const pagableDataConverter: TFetchConverter<
  TPageableDataUI,
  TPageableDataDB
> = {
  fromDb: data => {
    return {
      totalElements: data.totalElements,
      totalPages: data.totalPages,
      number: data.number,
      size: data.size,
    };
  },
};

export const allowOperationsConverter: TFetchConverter<
  TAllowedOperationsUI,
  TAllowedOperationsDB
> = {
  fromDb: data => {
    return {
      readonly: !data?.includes(EAllowedOperations.UPDATE),
      canRemove: !!data?.includes(EAllowedOperations.REMOVE),
      canAdd: !!data?.includes(EAllowedOperations.ADD),
    };
  },
};

export const allowOperationsWithStringValueConverter: TFetchConverter<
  TAllowedOperationsValueUI<string>,
  TAllowedOperationsValueDB<string>
> = {
  fromDb: data => {
    return {
      value: data.value,
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
    };
  },
};
export const allowOperationsWithBooleanValueConverter: TFetchConverter<
  TAllowedOperationsValueUI<boolean>,
  TAllowedOperationsValueDB<boolean>
> = {
  fromDb: data => {
    return {
      value: data.value,
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
    };
  },
};

export const allowOperationsWithDateValueConverter: TFetchConverter<
  TAllowedOperationsValueUI<string>,
  TAllowedOperationsValueDB<string>
> = {
  fromDb: data => {
    return {
      value: data.value && dateConverter(data.value, dateFormatUI),
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
    };
  },
};

export const phoneNumberConverter: TConvertor<
  string | null | undefined,
  string | null
> = {
  toDb: phoneNumber => {
    if (phoneNumber) {
      // Remove any non-numeric characters from the input
      const numericValue = phoneNumber.replace(/\D/g, '');

      // Add the +1 prefix
      const formattedPhoneNumber = `+1${numericValue}`;

      return formattedPhoneNumber;
    }

    return null;
  },
  fromDb: phoneNumber => formatPhoneNumber(phoneNumber),
};

export const addressConvertor: TConvertor<TAddressTypesUI, TAddressTypesDB> = {
  fromDb: data => ({
    placeId: data.placeId,
    formattedAddress: data.formattedAddress,
    country: data.country,
    state: data.state && convertStateToUpperCase(data.state),
    city: data.city,
    streetNumber: data.streetNumber,
    street: data.street,
    zipCode: data.zipCode,
    latitude: data.latitude,
    longitude: data.longitude,
  }),
  toDb: data => ({
    placeId: data.placeId,
    formattedAddress: data.formattedAddress,
    country: data.country,
    state: data.state && convertStateToUpperCase(data.state),
    city: data.city,
    streetNumber: data.streetNumber,
    street: data.street,
    zipCode: data.zipCode,
    latitude: data.latitude,
    longitude: data.longitude,
  }),
};

export const yesNoOptionConverter: TConvertor<
  TYesNoOptionType,
  boolean | null | undefined
> = {
  fromDb: data => {
    if (data) {
      return 'YES';
    }
    if (data === false) {
      return 'NO';
    }
    return null;
  },
  toDb: data => {
    if (!data) {
      return null;
    }
    return data === 'YES';
  },
};

export const citiesOptionsConverter: TFetchConverter<TOption[], string[]> = {
  fromDb: data => (data || []).map(city => ({ label: city, value: city })),
};

export const i18nLanguageConverter: TCreateConverter<
  EI18nLanguageUI,
  EI18nLanguageDB
> = {
  toDb: data => {
    const status = {
      [EI18nLanguageUI.ENGLISH]: EI18nLanguageDB.ENGLISH,
      [EI18nLanguageUI.SPANISH]: EI18nLanguageDB.SPANISH,
    };
    return status[data];
  },
};
