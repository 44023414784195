export enum ERedirectActionTypeDB {
  SHOW_WORKSHOP_EVENT = 'showWorkshopEvent',
  SUBMIT_SHIPPING_DETAILS = 'submitShippingDetails',
  OPEN_MEETING_LINK = 'openMeetingLink',
  SHOW_HOST_EVENT = 'showHostEvents',
}

export enum ERedirectActionTypeUI {
  SHOW_WORKSHOP_EVENT = 'showWorkshopEvent',
  SUBMIT_SHIPPING_DETAILS = 'submitShippingDetails',
  OPEN_MEETING_LINK = 'openMeetingLink',
  SHOW_HOST_EVENT = 'showHostEvents',
}
