import { Suspense, FC, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import appSlice from 'redux/slices/app.slice';
import { useAppDispatch, useAppSelector } from 'redux/store';
import PublicRoutes from './PublicRoutes';
import { outsideAppLayoutRoutes } from './RoutesData';

const RoutesPage: FC = () => {
  const navigate = useNavigate();
  const redirectLink = useAppSelector(
    state => state.appSlice.currentNotificationUrl,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (redirectLink) {
      navigate(redirectLink);
      dispatch(appSlice.actions.setCurrentNotificationUrl(''));
    }
  }, []);

  return (
    <Suspense fallback={false}>
      <Routes>
        <Route path="/*" element={<PublicRoutes />} />
        <Route>
          {
            outsideAppLayoutRoutes.map(({ path, component }) => {
              const Component = component;
              return (
                <Route key={path} path={`${path}`} element={<Component />} />
              );
            })
          }

        </Route>
      </Routes>
    </Suspense>
  );
};
export default RoutesPage;
