import { customScrollbarStyle } from '@idaho-aeyc/ui-kit';
import { generateSpaceClassNames } from 'helpers/utils';
import { createGlobalStyle } from 'styled-components';

const spaces = [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40];

export const GenericStyles = createGlobalStyle`
  ${() => generateSpaceClassNames(spaces)}

  ${customScrollbarStyle}
  
  .text-underline {
    text-decoration: underline;
  }

  .text-line-through {
    text-decoration: line-through
  }

  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }

  .h-100 {
    height: 100%;
  }
  .w-100 {
    width: 100%;
  }

  .mr-auto {
    margin-right: auto
  }
  .ml-auto {
    margin-left: auto
  }

  .m-auto {
    margin: 0 auto
  }

  .pointer {
    cursor: pointer;
  }

  .no-border {
    border: none !important;
  }

  .position-relative {
    position: relative
  }

  .position-fixed {
    position: fixed
  } 

  // TODO: NONA | MARTIROS find a way to import dropdown global styles frim ui-kit
  
  .custom-option {
    border: 1px solid ${({ theme }) => theme.customOptionColorBorder};
    border-radius: 1px solid  ${({ theme }) =>
      theme.borderRadiusS}px; //Todo change after token added
    margin-bottom:  ${({ theme }) =>
      theme.customOptionSpacingMarginBottomBody}px;
    font-size:  ${({ theme }) => theme.customOptionFontSize}px;
    font-weight:  ${({ theme }) => theme.customOptionFontWeight};

    .ant-col:first-of-type {
      height: 24px; // Todo: change after token added
      margin-bottom:  ${({ theme }) =>
        theme.customOptionSpacingMarginBottomIcon};
      display: flex;
      align-items: center;

      .icon {
        width:  ${({ theme }) => theme.customOptionSizingIcon}px;

        svg {
          fill:  ${({ theme }) => theme.customOptionColorIcon};
        }
      }
    }
  }

  .ant-table-filter-dropdown-btns {
    gap:  ${({ theme }) => theme.paddingXs}px;

    .ant-btn {
      width: 50%;
      border-radius:  ${({ theme }) => theme.buttonBorderRadiusMd}px;
    }

    .ant-btn-link {
      color:  ${({ theme }) => theme.colorBlackPrimary};
      border:  ${({ theme }) => theme.buttonBorderLg}  ${({ theme }) =>
  theme.buttonColorPrimaryNormalBg};
      &:disabled {
        color: ${({ theme }) => theme.buttonColorPrimaryDisabledText} ;
        background-color: ${({ theme }) =>
          theme.buttonColorPrimaryDisabledBg} ; 
          border-color: ${({ theme }) => theme.buttonColorPrimaryDisabledBg};
      }
    }
  }

  // notification styling
  .ant-notification-notice {
    &&.custom-notification {
        padding: 16px;
        border-radius: ${({ theme }) => theme.notificationBorderRadius}px;
        background-color: ${({ theme }) => theme.notificationColorBg};
        .ant-notification-notice-content {
          padding-right: 16px;
        }
        .ant-notification-notice-icon {
            display: none
        }
        .ant-notification-notice-message {
          margin-inline-start: 0;
          margin-bottom: 0;
          color: ${({ theme }) => theme.colorWhitePrimary};
        }
        .ant-notification-notice-close {
          top: 16px;
        }
    }
  }

`;
