import styled from 'styled-components';
import {
  Paragraph,
  Text,
  Title,
  Link,
  SecondaryText,
  MessageLabel,
} from '@idaho-aeyc/ui-kit';

export const STitle = styled(Title)`
  // Todo: investigate why the title is not changing the font-family and color  passed by props
  &&& {
    font-family: ${({ theme }) => theme.fontFamilyMontserrat};
    color: ${props => props.color};
  }
`;

export const SText = styled(Text)``;

export const SSecondaryText = styled(SecondaryText)``;

export const SLink = styled(Link)``;

export const SParagraph = styled(Paragraph)``;

export const SMessageLabel = styled(MessageLabel)``;
