import { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import appSlice from 'redux/slices/app.slice';
import useElementResize from 'hooks/useElementResize';
import { SContent, SLayoutWrapper, SMainContent } from './Layout.styles';
import Footer from './Footer';
import { Col } from '../Grid';
import ScrollToTop from '../ScrollToTop';
import Header from './Header';

const Layout = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { height: headerHeight } = useElementResize({
    ref: headerRef,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(appSlice.actions.setHeaderHeight(headerHeight));
  }, [headerHeight]);

  return (
    <SLayoutWrapper>
      <Header ref={headerRef} />
      <SContent $headerHeight={headerHeight}>
        <SMainContent justify="center">
          <Outlet />
        </SMainContent>
        <Col>
          <Footer />
        </Col>
      </SContent>
      <ScrollToTop />
    </SLayoutWrapper>
  );
};

export default Layout;
