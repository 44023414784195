import styled from 'styled-components';

export const SMobileNavBar = styled.nav<{ $open: boolean }>`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  transform: ${({ $open }) => ($open ? 'translateY(0)' : 'translateY(100%)')};
  opacity: ${({ $open }) => ($open ? '1' : '0')};
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  background: white;
  text-align: center;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1), opacity 300ms 0ms;
  width: 100%;
`;

export const SNavItem = styled.div<{ $active: boolean }>`
  position: relative;
  font-family: Poppins;
  width: 100%;
  box-sizing: border-box;
  padding-left: 4vw;
  padding-right: 4vw;
  color: black;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0em;
  text-transform: none;
  font-size: 8.5vmin;
  line-height: 1;
  span {
    ${props =>
      props.$active &&
      `background-image: linear-gradient(currentColor,currentColor);
    background-repeat: repeat-x;
    background-size: 1px 1px;
    background-position: 0 100%;`}
  }
  a {
    position: relative;
    color: black;
    display: block;
    margin: 3vw 5vw;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`;
