import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = `${process.env.REACT_APP_HOSTNAME}/api/v1`;

export const emptySplitApi = (reducerPath: string, tagTypes?: string[]) =>
  createApi({
    reducerPath,
    tagTypes: tagTypes || [],
    baseQuery: fetchBaseQuery({
      baseUrl,
    }),
    endpoints: () => ({}),
  });
