import { citiesOptionsConverter } from 'data/convertors/general.converters';
import {
  kitOrderingShippingAddressConverter,
  registerWorkshopConverter,
  notifyMeConverter,
  workshopsListConverter,
  workshopConverter,
  registrationFormDataConverter,
  lightBoxConverter,
} from 'data/convertors/workshops.convertors';
import { TOption } from 'data/types/generic.types';
import {
  TKitShippingAddressEditUI,
  TWorkshopDB,
  TWorkshopRegisterDataUI,
  TWorkshopUI,
  TSubscribeDataUI,
  TWorkshopDetailUI,
  TWorkshopDetailDB,
  TWorkshopRegisterResponseUI,
  TWorkshopRegisterResponseDB,
  TRegistrationFormDataUI,
  TRegistrationFormDataDB,
  TLightBoxUI,
  TLightBoxDB,
} from 'data/types/workshops.types';
import {
  EWorkshopsUrls,
  meetingLinkUrl,
  getRegistrationModalDataUrl,
  getRegistrationFreeSlotsUrl,
} from 'data/urls/workshops.url';
import { emptySplitApi } from 'redux/helpers/slice.helpers';

const baseUrl = `${EWorkshopsUrls.WORKSHOPS}`;

export const workshopsApi = emptySplitApi('workshops', [
  'workshops',
  'workshop',
  'workshopsList',
  'shipping-detail',
  'free-slot',
]).injectEndpoints({
  endpoints: build => ({
    registerWorkshop: build.mutation<
      TWorkshopRegisterResponseUI,
      TWorkshopRegisterDataUI
    >({
      query(request) {
        const { recaptchaResponse, userLanguage, ...body } =
          registerWorkshopConverter.toDb(request);
        return {
          url: `${baseUrl}/${EWorkshopsUrls.REGISTRATION}`,
          method: 'POST',
          headers: {
            'g-recaptcha-response': recaptchaResponse,
            'x-user-language': userLanguage,
          },
          body,
        };
      },
      invalidatesTags: ['workshops'],
      transformResponse: (response: TWorkshopRegisterResponseDB) =>
        registerWorkshopConverter.fromDb(response),
    }),
    getWorkshopsList: build.query<TWorkshopUI, string>({
      // @ts-ignore
      query(params) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.WORKSHOP_EVENT}`,
          method: 'GET',
          params,
        };
      },
      transformResponse: (data: TWorkshopDB) =>
        workshopsListConverter.fromDb(data),
      providesTags: ['workshopsList'],
    }),
    getWorkshopsById: build.query<TWorkshopDetailUI, string>({
      query(id) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.WORKSHOP_EVENT}/${id}`,
          method: 'GET',
        };
      },
      transformResponse: (response: TWorkshopDetailDB) =>
        workshopConverter.fromDb(response),
      providesTags: ['workshop'],
    }),
    getWorkshopCities: build.query<TOption[], void>({
      query() {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.WORKSHOP_CITIES}`,
          method: 'GET',
        };
      },
      transformResponse: (response: string[]) =>
        citiesOptionsConverter.fromDb(response),
    }),
    getShippingDetail: build.query<
      TWorkshopUI,
      { parentOrderId: string | null }
    >({
      query({ parentOrderId }) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.PARENT_ORDERING}/${EWorkshopsUrls.SHIPPING_DETAILS}/${EWorkshopsUrls.VALIDATE}`,
          method: 'GET',
          params: { parentOrderId },
        };
      },
    }),
    addKitOrderingShippingAddress: build.mutation<
      void,
      TKitShippingAddressEditUI
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.PARENT_ORDERING}/${EWorkshopsUrls.SHIPPING_DETAILS}`,
          method: 'PATCH',
          body: kitOrderingShippingAddressConverter.toDb(body),
        };
      },
    }),
    subscribeMe: build.mutation<void, TSubscribeDataUI>({
      query(request) {
        const { recaptchaResponse, ...body } = notifyMeConverter.toDb(request);
        return {
          url: `${baseUrl}/${EWorkshopsUrls.SUBSCRIBE}`,
          method: 'POST',
          headers: {
            'g-recaptcha-response': recaptchaResponse,
          },
          body,
        };
      },
    }),
    getIdahoCities: build.query<string[], void>({
      query() {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.CITIES}/${EWorkshopsUrls.IDAHO}`,
          method: 'GET',
        };
      },
    }),
    getRegistrationFreeSlots: build.query<
      boolean,
      { workshopClassId?: string; workshopId: string }
    >({
      query({ workshopClassId, workshopId }) {
        return {
          url: getRegistrationFreeSlotsUrl,
          method: 'GET',
          params: { workshopClassId, workshopId },
        };
      },
      providesTags: ['free-slot'],
    }),
    getRegistrationFormData: build.query<
      TRegistrationFormDataUI,
      { workshopId: string }
    >({
      query({ workshopId }) {
        return {
          url: `${getRegistrationModalDataUrl}/${workshopId}`,
          method: 'GET',
        };
      },
      transformResponse: (response: TRegistrationFormDataDB) =>
        registrationFormDataConverter.fromDb(response),
    }),
    getMeetingLink: build.query<string, { workshopId: string }>({
      query({ workshopId }) {
        return {
          url: meetingLinkUrl,
          method: 'GET',
          params: { workshopId },
        };
      },
    }),
    getLightBox: build.query<TLightBoxUI[], void>({
      query() {
        return {
          url: `${EWorkshopsUrls.WORKSHOPS}/${EWorkshopsUrls.LIGHT_BOX}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TLightBoxDB[]) => {
        return data?.length
          ? data.map(item => lightBoxConverter.fromDb(item))
          : [];
      },
    }),
  }),
});

export const {
  useRegisterWorkshopMutation,
  useGetWorkshopsByIdQuery,
  useGetWorkshopsListQuery,
  useGetWorkshopCitiesQuery,
  useGetShippingDetailQuery,
  useAddKitOrderingShippingAddressMutation,
  useSubscribeMeMutation,
  useGetIdahoCitiesQuery,
  useLazyGetRegistrationFreeSlotsQuery,
  useGetRegistrationFreeSlotsQuery,
  useGetRegistrationFormDataQuery,
  useGetMeetingLinkQuery,
  useGetLightBoxQuery,
} = workshopsApi;
