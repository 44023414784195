import { ForwardedRef, forwardRef } from 'react';
import { Col } from 'components/Base/Grid';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import { SRow } from './Header.styles';

export const Header = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <SRow ref={ref} className="position-fixed w-100">
      <Col xs={0} md={24}>
        <DesktopHeader />
      </Col>
      <Col xs={24} md={0}>
        <MobileHeader />
      </Col>
    </SRow>
  );
});

export default Header;
