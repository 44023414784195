import logo from 'assets/images/logo.png';
import { Col, Row } from 'components/Base/Grid';
import { Image } from 'components/Base/Image';
import { RoutesPaths } from 'routes/Routes.types';
import DesktopNavBar from '../DesktopNavBar';
import { SDesktopHeader } from './DesktopHeader.styles';

export const Header = () => {
  const onImageClick = () => {
    window.location.replace(RoutesPaths.HOME);
  };
  return (
    <SDesktopHeader>
      <Col span={24}>
        <Row justify="space-between" align="middle">
          <Col>
            <Image
              className="pointer"
              src={logo}
              alt="header logo"
              height={63}
              onClick={onImageClick}
            />
          </Col>
          <Col>
            <DesktopNavBar />
          </Col>
        </Row>
      </Col>
    </SDesktopHeader>
  );
};

export default Header;
