import { useTranslation } from '@packages/utils';
import pageNotFoundImage from 'assets/images/badSmile.svg';
import ErrorPageView from '..';

const SomethingWentWrong = () => {
  const { t } = useTranslation(  {
    keyPrefix:
      'descriptions.workshop-landing.registration-modal.something-went-wrong',
  });
  return (
    <ErrorPageView
      imgSrc={pageNotFoundImage}
      alt=""
      title={t('TITLE')}
      description={t('DESCRIPTION')}
    />
  );
};

export default SomethingWentWrong;
