import styled from 'styled-components';
import { Row } from 'components/Base/Grid';

export const SDesktopHeader = styled(Row)`
  background-color: white;
  z-index: 99;
  width: 100%;
  box-sizing: border-box;
  padding: 2.2vw 4vw;
  pointer-events: auto;
`;
