import { ERedirectActionTypeUI } from 'data/types/redirect.types';
import { RoutesPaths } from 'routes/Routes.types';

export type TRedirectMapperProps = {
  url: string;
  private: boolean;
};
export const redirectsMapper: {
  [key in ERedirectActionTypeUI]: TRedirectMapperProps;
} = {
  [ERedirectActionTypeUI.SHOW_WORKSHOP_EVENT]: {
    private: false,
    url: `${RoutesPaths.WORKSHOP}`,
  },
  [ERedirectActionTypeUI.SUBMIT_SHIPPING_DETAILS]: {
    private: false,
    url: `${RoutesPaths.KIT_SHIPPING_BY_ID}`,
  },
  [ERedirectActionTypeUI.OPEN_MEETING_LINK]: {
    private: false,
    url: `${RoutesPaths.MEETING_LINK}`,
  },
  [ERedirectActionTypeUI.SHOW_HOST_EVENT]: {
    private: false,
    url: `${RoutesPaths.WORKSHOPS}`,
  },
};
