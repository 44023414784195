import { FilterValue } from 'antd/es/table/interface';

export type TErrorField = {
  name: string[];
  errors: string[];
  warnings: string[];
};

export type TValidateFieldsError = {
  errorFields: TErrorField[];
  outOfDate: boolean;
};

export type TOption = {
  value: string;
  label: string;
};

export type TPageableDataUI = {
  number: number;
  totalPages: number;
  totalElements: number;
  size: number;
};
export type TPageableDataDB = {
  number: number;
  totalPages: number;
  totalElements: number;
  size: number;
};

export type TPageableDataWithContentUI<T> = TPageableDataUI & {
  content: T;
};
export type TPageableDataWithContentDB<T> = TPageableDataDB & {
  content: T;
};

export enum ESortDirection {
  ascend = 'ASC',
  descend = 'DESC',
}

export type TSortParams = {
  sortField?: string;
  sortDirection?: ESortDirection;
};

export type TTableFilters<F extends string | number | symbol = string> = {
  page: number;
  pageSize: number;
  sortColumn: TSortParams;
  filters?: Record<F, FilterValue | null>;
};

export enum EAllowedOperations {
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
  ADD = 'ADD',
}

export type TAllowedOperationsDB = EAllowedOperations[];

export type TAllowedOperationsUI = {
  readonly?: boolean;
  canRemove?: boolean;
};

export type TAllowedOperationsValueUI<T extends boolean | string> = {
  allowedOperations: TAllowedOperationsUI;
  value: T;
};
export type TAllowedOperationsValueDB<T extends boolean | string> = {
  allowedOperations: TAllowedOperationsDB;
  value: T;
};

export type TAddressShortDataDB = {
  formattedAddress: string;
  id: string;
};
export type TAddressShortDataUI = {
  formattedAddress: string;
  id: string;
};

export type TRtkErrorType = {
  code?: number;
  data: {
    message: string;
  };
  status?: number;
} | null;

export type TAddressTypesUI = {
  placeId?: string;
  formattedAddress?: string;
  country?: string;
  state?: string;
  city?: string;
  streetNumber?: string;
  street?: string;
  zipCode?: string;
  latitude?: number;
  longitude?: number;
};

export type TAddressTypesDB = {
  placeId?: string;
  formattedAddress?: string;
  country?: string;
  state?: string;
  city?: string;
  streetNumber?: string;
  street?: string;
  zipCode?: string;
  latitude?: number;
  longitude?: number;
};

export type TYesNoOptionType = 'YES' | 'NO' | null;

export enum ELanguage {
  ENGLISH = 'ENGLISH',
  SPANISH = 'SPANISH',
}

export enum EI18nLanguageDB {
  ENGLISH = 'EN',
  SPANISH = 'ES',
}
