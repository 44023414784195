import { Button } from '@packages/utils';
import styled from 'styled-components';

export const SButton = styled(Button)`
  &.ant-btn {
    width: 42px;
    height: 42px;
    position: fixed;
    right: 64px;
    bottom: 80px;
    .ant-btn-icon {
      transform: rotate(180deg);
    }
  }
`;
