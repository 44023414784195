import { styled } from 'styled-components';
import { Content } from '@idaho-aeyc/ui-kit';
import { Row } from 'components/Base/Grid';

export const SLayoutWrapper = styled.div`
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colorWhiteBgLayout};
  * {
    box-sizing: border-box;
  }
`;

export const SContent = styled(Content)<{ $headerHeight: number }>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-top: ${props => `calc(${props.$headerHeight}px - 1px)`};
`;

export const SMainContent = styled(Row)`
  display: flex;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colorWhitePrimary};
`;
