import { navbarRoutes } from 'routes/RoutesData';
import { useLocation } from 'react-router-dom';
import { SDesktopNavBar, SNavItem } from './DesktopNavBar.styles';

const DesktopNavBar = () => {
  const { pathname } = useLocation();
  return (
    <SDesktopNavBar>
      {navbarRoutes.map(({ path, title }) => (
        <SNavItem key={path} $active={pathname.replace('/', '') === path}>
          <a href={path}>{title}</a>
        </SNavItem>
      ))}
    </SDesktopNavBar>
  );
};
export default DesktopNavBar;
