import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { EIconNames, Icon } from 'components/Base/Icon';
import { SButton } from './ScrollToTop.styles';

const ScrollToTop = () => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <SButton
          shape="circle"
          type="primary"
          color="primary"
          onClick={scrollToTop}
          icon={
            <Icon
              alt="arrow"
              icon={EIconNames.ARROW_FILLED_DOWN}
              size={16}
              className="pointer"
              color={theme.colorWhitePrimary}
            />
          }
        />
      )}
    </>
  );
};

export default ScrollToTop;
