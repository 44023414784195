import { StyledBurger } from './Burger.styles';
import { TBurgerProps } from './Burger.types';

const Burger = ({ open, setOpen }: TBurgerProps) => {
  return (
    <StyledBurger $open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
    </StyledBurger>
  );
};

export default Burger;
