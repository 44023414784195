import { Col, Row } from 'components/Base/Grid';
import { Title } from 'components/Base/Typography';
import { RoutesPaths } from 'routes/Routes.types';
import { footerRoutes } from 'routes/RoutesData';
import logo from 'assets/images/footerLogo.png';
import { Image } from 'components/Base/Image';
import {
  SButtonsWrapper,
  SFooter,
  SLinkButton,
  SMobileLogo,
} from './Footer.styles';

export const Footer = () => {
  const onImageClick = () => {
    window.location.replace(RoutesPaths.HOME);
  };
  const onFooterButtonClick = (path: string) => {
    window.location.replace(path);
  };
  return (
    <SFooter justify="center" id="footer">
      <Row justify="center">
        <Col xs={0} md={24}>
          <Image
            className="pointer"
            src={logo}
            alt="footer logo"
            height={48}
            onClick={onImageClick}
          />
        </Col>
        <Col span={24}>
          <Title level={3}>
            READY! For Kindergarten | A Project of Idaho AEYC
          </Title>
        </Col>
        <Col span={24}>
          <a className="contact pointer" href={RoutesPaths.CONTACT}>
            CONTACT READY! STAFF
          </a>
        </Col>
        <Col span={24} className="content">
          <p>©2022 Idaho Association for the Education of Young Children</p>
          <p>
            208.345.1090
            <br />
            4335 W Emerald St. Ste 250
            <br />
            Boise, Idaho 83704
          </p>
        </Col>
      </Row>
      <SButtonsWrapper
        gutter={[{ xs: 0, md: 64 }, 12]}
        justify="space-around"
        wrap={false}
      >
        {footerRoutes.map(({ path, title }) => (
          <Col className="button-wrapper" key={path}>
            <SLinkButton
              className="button pointer"
              onClick={() => onFooterButtonClick(path)}
            >
              {title}
            </SLinkButton>
          </Col>
        ))}
      </SButtonsWrapper>
      <SMobileLogo xs={24} md={0} className="mt-10">
        <Image
          src={logo}
          alt="footer logo"
          height={48}
          onClick={onImageClick}
          className="pointer"
        />
      </SMobileLogo>
    </SFooter>
  );
};
export default Footer;
