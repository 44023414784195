import { TConvertor } from 'data/types/converter.types';
import {
  ERedirectActionTypeDB,
  ERedirectActionTypeUI,
} from 'data/types/redirect.types';

export const redirectActionTypeConverter: TConvertor<
  ERedirectActionTypeUI,
  ERedirectActionTypeDB
> = {
  fromDb: data => {
    const dataMapper: {
      [key in ERedirectActionTypeDB]: ERedirectActionTypeUI;
    } = {
      [ERedirectActionTypeDB.SHOW_WORKSHOP_EVENT]:
        ERedirectActionTypeUI.SHOW_WORKSHOP_EVENT,
      [ERedirectActionTypeDB.SUBMIT_SHIPPING_DETAILS]:
        ERedirectActionTypeUI.SUBMIT_SHIPPING_DETAILS,
      [ERedirectActionTypeDB.OPEN_MEETING_LINK]:
        ERedirectActionTypeUI.OPEN_MEETING_LINK,
      [ERedirectActionTypeDB.SHOW_HOST_EVENT]:
        ERedirectActionTypeUI.SHOW_HOST_EVENT,
    };
    return dataMapper[data];
  },
  toDb: data => {
    const dataMapper: {
      [key in ERedirectActionTypeUI]: ERedirectActionTypeDB;
    } = {
      [ERedirectActionTypeUI.SHOW_WORKSHOP_EVENT]:
        ERedirectActionTypeDB.SHOW_WORKSHOP_EVENT,
      [ERedirectActionTypeUI.SUBMIT_SHIPPING_DETAILS]:
        ERedirectActionTypeDB.SUBMIT_SHIPPING_DETAILS,
      [ERedirectActionTypeUI.OPEN_MEETING_LINK]:
        ERedirectActionTypeDB.OPEN_MEETING_LINK,
      [ERedirectActionTypeUI.SHOW_HOST_EVENT]:
        ERedirectActionTypeDB.SHOW_HOST_EVENT,
    };
    return dataMapper[data];
  },
};
