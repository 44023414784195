import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TAppSliceState = {
  isFilterOpen: boolean;
  hasSelectedFilterItem: boolean;
  currentNotificationUrl: string;
  headerHeight: number;
};

const initialState: TAppSliceState = {
  isFilterOpen: false,
  hasSelectedFilterItem: false,
  currentNotificationUrl: '',
  headerHeight: 0,
};

const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    toggleFilterModal(state) {
      state.isFilterOpen = !state.isFilterOpen;
    },
    toggleHasSelectedFilterItem(
      state,
      action: PayloadAction<{ hasSelectedFilter: boolean }>,
    ) {
      state.hasSelectedFilterItem = action.payload.hasSelectedFilter;
    },
    setCurrentNotificationUrl(state, action: PayloadAction<string>) {
      state.currentNotificationUrl = action.payload;
    },
    setHeaderHeight(state, action: PayloadAction<number>) {
      state.headerHeight = action.payload;
    },
  },
});

export default appSlice;
